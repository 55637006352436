import React from "react";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";

import "../assets/styles/theme.scss";

import Seo from "../components/seo";
import Header from "../components/header.js";
import Footer from "../components/footer.js";

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.siteTitle;
  const site = data?.site;

  const styleTheme1 = `${site?.siteMetadata?.cmsURL}/wp-content/themes/hello-elementor/style.min.css?ver=2.5.0`;
  const styleTheme2 = `${site?.siteMetadata?.cmsURL}/wp-content/themes/hello-elementor/theme.min.css?ver=2.5.0`;
  const styleElementorFrontEnd = `${site?.siteMetadata?.cmsURL}/wp-content/plugins/elementor/assets/css/frontend-lite.min.css?ver=3.6.0`;
  const styleElementorConfig = `${site?.siteMetadata?.cmsURL}/wp-content/uploads/elementor/css/post-21.css`;
  const styleElementorIcons = `${site?.siteMetadata?.cmsURL}/wp-content/plugins/elementor/assets/lib/eicons/css/elementor-icons.min.css?ver=5.15.0`;
  const styleElementorFontAwesome = `${site?.siteMetadata?.cmsURL}/wp-content/plugins/elementor/assets/lib/font-awesome/css/fontawesome.min.css?ver=5.15.3`;
  const styleElementorFASolid = `${site?.siteMetadata?.cmsURL}/wp-content/plugins/elementor/assets/lib/font-awesome/css/solid.min.css?ver=5.15.3`;
  const styleElementorBrands = `${site?.siteMetadata?.cmsURL}/wp-content/plugins/elementor/assets/lib/font-awesome/css/brands.min.css?ver=5.15.3`;
  const styleElementorPro = `${site?.siteMetadata?.cmsURL}/wp-content/plugins/elementor-pro/assets/css/frontend-lite.min.css?ver=3.6.4`;
  const styleElementorGlobal = `${site?.siteMetadata?.cmsURL}/wp-content/uploads/elementor/css/global.css}`;

  return (
    <div className="page-container">
      <Seo title={`Page Not Found`} />

      <Helmet
        bodyAttributes={{
          class: "elementor-default elementor-page elementor-kit-21",
        }}
      >
        <link rel="icon" href={`/favicon.png`} sizes="32x32" />
        <link rel="icon" href={`/favicon.png`} sizes="192x192" />
        <link rel="apple-touch-icon" href={`/favicon.png`} />
        <link type="text/css" rel="stylesheet" href={styleTheme1} />
        <link type="text/css" rel="stylesheet" href={styleTheme2} />
        <link type="text/css" rel="stylesheet" href={styleElementorFrontEnd} />
        <link type="text/css" rel="stylesheet" href={styleElementorConfig} />
        <link type="text/css" rel="stylesheet" href={styleElementorIcons} />
        <link
          type="text/css"
          rel="stylesheet"
          href={styleElementorFontAwesome}
        />
        <link type="text/css" rel="stylesheet" href={styleElementorFASolid} />
        <link type="text/css" rel="stylesheet" href={styleElementorBrands} />
        <link type="text/css" rel="stylesheet" href={styleElementorPro} />
        <link
          type="text/css"
          rel="stylesheet"
          href={styleElementorGlobal}
          media="all"
        />
      </Helmet>

      <Header siteTitle={siteTitle} />
      <div className="page-container-inner">
        <div className="container-404">
          <div className="main-container">
            <h1>Page Not Found</h1>
            <p>It looks like nothing was found at this location.</p>
            <Link to="/" className="button-general">
              Back to Homepage
            </Link>
          </div>
        </div>
      </div>
      <Footer siteTitle={siteTitle} />
    </div>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        siteTitle
        cmsURL
      }
    }
  }
`;
